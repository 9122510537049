<template>
    <div class="menu" style="overflow:hidden;min-width: 1500px;">

        <div style="width: 1500px; margin: 108px auto">
            <div style="width: 100%; height: 60px; margin-bottom: 2px; background-color: #FFFFFF; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600)">
                <span class="hand op" style="font-size: 18px; font-weight: 600; line-height: 60px; margin-left: 30px" @click="goBack"><i class="el-icon-back"></i> 返回</span>
                <span style="font-size:20px; font-weight: 600; line-height: 60px; margin-left: 40%">管理Room菜单</span>
            </div>

            <div style="width: 1380px; background-color: #FFFFFF; padding: 20px 60px">
                <div style="margin-bottom: 12px; display: flex; justify-content: space-between">
                    <div>
                        <span class="resume-title">
                        标准发布菜单
                        </span>
                        <span class="resume-res">（此项只显示创建Room时选择过的菜单，如需其他默认菜单则要在编辑Room信息页面新添加默认菜单，该菜单自带模板格式）</span>
                    </div>
                    <div>
                        <span class="hand op" style="color: #2970FF" @click="addMenu('1')">添加标准发布菜单</span>
                    </div>

                </div>
                <div class="card-box">
                    <div class="card" v-for="(item, index) in defaultMenu" :key="index">
                        <div class="card-title">
                            <span>{{item.name}}</span>
                            <div style="color: #2970FF; font-size: 14px">
                                <span class="hand op" style="margin-right: 12px" @click="editMenu(item)">编辑</span>
                                <span class="hand op" @click="deleteMenu(item.id)">删除</span>
                            </div>
                        </div>
                        <dl>
                            <dt class="card-li">
                                <div style="display: flex">
                                    <span style="font-size: 14px">可发<span style="margin: 0 20px 0 10px">|</span></span>
                                    <el-checkbox-group v-model="item.writePermission" @change="selectWritePermission($event, item)">
                                        <el-checkbox label="2">管理员</el-checkbox>
                                        <el-checkbox label="3">成员</el-checkbox>
                                        <el-checkbox label="5">关注者</el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </dt>
                            <dt class="card-li">
                                <div style="display: flex">
                                    <span style="font-size: 14px">可读<span style="margin: 0 20px 0 10px">|</span></span>
                                    <el-checkbox-group v-model="item.readPermission" @change="selectWritePermission($event, item)">
                                        <el-checkbox label="2">管理员</el-checkbox>
                                        <el-checkbox label="3">成员</el-checkbox>
                                        <el-checkbox label="5">关注者</el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </dt>
                        </dl>
                    </div>
                </div>

                <div style="margin-bottom: 12px; margin-top: 20px; display: flex; justify-content: space-between">
                    <div>
                      <span class="resume-title">
                          通用模板菜单
                      </span>
                      <span class="resume-res">（模板菜单可选择系统推荐的通用模板格式，选择后自动创建使用该模板名称的菜单）</span>
                    </div>
                    <div>
                      <span class="hand op" style="color: #2970FF" @click="addMenu('2')">添加通用模板菜单</span>
                    </div>
                </div>
                <div class="card-box">
                    <div class="card" v-for="(item, index) in hotDiyTemplates" :key="index">
                        <div class="card-title">
                            <el-checkbox v-model="item.normal" @change="deletePush($event, item)">{{item.name}}</el-checkbox>
                        </div>
                        <dl v-if="item.normal === true">
                            <dt class="card-li">
                                <div style="display: flex">
                                    <span style="font-size: 14px">可发<span style="margin: 0 20px 0 10px">|</span></span>
                                    <el-checkbox-group v-model="item.writePermission" @change="selectWritePermission($event, item)">
                                        <el-checkbox label="2">管理员</el-checkbox>
                                        <el-checkbox label="3">成员</el-checkbox>
                                        <el-checkbox label="5">关注者</el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </dt>
                            <dt class="card-li">
                                <div style="display: flex">
                                    <span style="font-size: 14px">可读<span style="margin: 0 20px 0 10px">|</span></span>
                                    <el-checkbox-group v-model="item.readPermission" @change="selectWritePermission($event, item)">
                                        <el-checkbox label="2">管理员</el-checkbox>
                                        <el-checkbox label="3">成员</el-checkbox>
                                        <el-checkbox label="5">关注者</el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </dt>
                        </dl>
                    </div>
                </div>

<!--                <div class="card-box">-->
<!--                    <div class="card" v-for="(item, index) in hotList" :key="index">-->
<!--                        <div class="card-title">-->
<!--                            <el-checkbox v-model="item.normal" @change="selectPush($event, item)">{{item.name}}</el-checkbox>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

                <div style="margin: 20px 0; display: flex; justify-content: space-between">
                    <div>
                        <span class="resume-title">
                            自定义模板菜单
                         </span>
                        <span class="resume-res">（自定义模板菜单可新建菜单名称并选择用户自己创建保存的模板格式，也可以：<span @click="utils.b('/form/diy')" class="hand op" style="color:#2970FF">>新建自定义模板文档</span><span>）</span></span>
                    </div>
                    <div>
                        <span class="hand op" style="color: #2970FF; margin-right: 30px" @click="addMenu('3')">添加自定义模板菜单</span>
                        <span class="hand op" style="color: #2970FF" @click=" manageRoomTel('3')">管理Room模板</span>
                    </div>
                </div>
                <div class="card-box">
                    <div class="card" v-for="(item, index) in selfMenuList" :key="index">
                        <div class="card-title">
                            <span>{{item.name}}</span>
                            <div style="color: #2970FF; font-size: 14px">
                                <span class="hand op" style="margin-right: 12px" @click="editMenu(item)">编辑</span>
                                <span class="hand op" @click="deleteMenu(item.id)">删除</span>
                            </div>
                        </div>
                        <dl>
                            <dt class="card-li">
                                <div style="display: flex">
                                    <span style="font-size: 14px">可发<span style="margin: 0 20px 0 10px">|</span></span>
                                    <el-checkbox-group v-model="item.writePermission" @change="selectWritePermission($event, item)">
                                        <el-checkbox label="2">管理员</el-checkbox>
                                        <el-checkbox label="3">成员</el-checkbox>
                                        <el-checkbox label="5">关注者</el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </dt>
                            <dt class="card-li">
                                <div style="display: flex">
                                    <span style="font-size: 14px">可读<span style="margin: 0 20px 0 10px">|</span></span>
                                    <el-checkbox-group v-model="item.readPermission" @change="selectWritePermission($event, item)">
                                        <el-checkbox label="2">管理员</el-checkbox>
                                        <el-checkbox label="3">成员</el-checkbox>
                                        <el-checkbox label="5">关注者</el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </dt>
                        </dl>
                    </div>
                </div>

                <div style="margin-bottom: 12px; margin-top: 20px" v-if="subMenuList.length > 0">
                    <span class="resume-title">
                        子Room菜单
                    </span>
                    <span class="resume-res">（来自子Room分享的菜单，可选择是否在本Room中使用）</span>
                </div>
                <div class="card-box" v-if="subMenuList.length > 0">
                    <div class="card" v-for="(item, index) in subMenuList" :key="index">
                        <div class="card-title">
                            <el-checkbox false-label="0" true-label="1" v-model="item.fidRoomAgreeShow" @change="selectSubMenu($event, item.id)">{{item.name}}</el-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog title="删除菜单" :visible.sync="showDeleteMenu" width="20%">
            <div style="color: #cc0000">是否确定删除此菜单？</div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="showDeleteMenu = false" size="small">取 消</el-button>
                <el-button type="primary" @click="deleteMenuClick" size="small">删 除</el-button>
            </span>
        </el-dialog>

        <el-dialog :title="menuId === '' ? '添加菜单' : '编辑菜单'" :visible.sync="showMenuAdd" width="50%">
            <el-form label-width="120px" size="medium" label-position="left">
                <el-form-item label="菜单内容格式" v-if="menuId === ''">
                    <el-select v-model="roomFrom.menuType" placeholder="菜单内容格式">
                        <el-option v-for="(item, index) in optionTemplate" :key="index" :label="item.title" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="菜单名称" v-if="menuClass != '2'">
                    <el-input v-model="roomFrom.name" :show-word-limit="true" maxlength="10" minlength="2" placeholder="菜单名称"></el-input>
                </el-form-item>

                <el-form-item label="菜单排序">
                    <el-input-number v-model="roomFrom.sort" :max="20" :min="1" placeholder="菜单排序"></el-input-number>
                </el-form-item>


                <el-form-item label="可发布">
                    <el-checkbox-group v-model="roomFrom.writePermission">
                        <el-checkbox label="2">管理员</el-checkbox>
                        <el-checkbox label="3">成员</el-checkbox>
                        <el-checkbox label="5">关注者</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>

                <el-form-item label="可阅读">
                    <el-checkbox-group v-model="roomFrom.readPermission">
                        <el-checkbox label="2">管理员</el-checkbox>
                        <el-checkbox label="3">成员</el-checkbox>
                        <el-checkbox label="5">关注者</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>

                <el-form-item label="在父Room展示" v-if="roomInfo.roomType === 3 && roomInfo.fid">
                    <el-switch v-model="roomFrom.isFidShow" style="float:left;margin:10px 0;" :active-value="1" :inactive-value="0"></el-switch>
                </el-form-item>

            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelMenu" size="small">取 消</el-button>
                <el-button type="primary" v-if="menuId !== ''" @click="changeMenu" size="small">编 辑</el-button>
                <el-button type="primary" @click="changeMenu" v-else size="small">添 加</el-button>
            </span>
        </el-dialog>

        <!--分享给Room-->
        <el-dialog class="show-message" :visible.sync="showRoom" width="740px">
            <div style="display: flex;">
                <div class="dialog-title">分享模板到Room</div>
            </div>
            <div class="message-divider">
                <el-divider></el-divider>
            </div>

            <div class="message-body">
                <div class="title-form">我的Room列表</div>
                <div style="display: flex; flex-wrap: wrap">
                    <div style="display: flex; justify-content: center;" class="list-content" v-for="(item, index) in myShowRoomList" :key="index">
                        <img class="head-ico" style="width: 40px; height: 40px; margin: auto 6px;" :src="item.cover">
                        <div style="margin: auto 0; width: 70%">
                            <div style="font-size: 16px; font-weight: 600; margin-bottom: 2px">
                                {{item.name}}
                            </div>
                        </div>
                        <div style="margin-top: 20px">
                            <el-radio v-model="roomId" :label="item.id"></el-radio>
                        </div>
                    </div>
                </div>
            </div>
            <div style="padding-bottom: 10px">
                <div @click="joinRoom" class="hand op" style="width: 180px; height: 40px; margin: 20px auto; text-align: center; line-height: 40px; border-radius: 5px; background-color: #2970FF; color: #FFFFFF; font-size: 16px; font-weight: 600">分享</div>
            </div>
        </el-dialog>

        <!--管理Room菜单-->
        <el-dialog class="show-message" :visible.sync="showManage" width="740px">
            <div style="display: flex;">
                <div class="dialog-title">管理Room菜单</div>
            </div>
            <div style="display: flex; flex-wrap: wrap; padding: 10px 0 20px 30px">
                <div v-for="(item, index) in optionTemplate" :key="index" style="margin: 0 30px 20px 0" @mousemove="item.show = true" @mouseleave="item.show = false">
                    <div style="width: 100%; height: 204px; background-color: #5A6FE6">
                        <div style="background: rgba(0,0,0,0.6); height: 204px" v-if="item.show === true">
                            <div style="display: flex; justify-content: end; color: #FFFFFF; font-size: 18px">
                                <i class="hand op el-icon-delete" style="margin: 12px 12px 0 0" @click="deleteRoomTemplate(item.id)"></i>
                            </div>

                            <div style="display: flex; justify-content: center; color: #FFFFFF; margin-top: 30px">
                                <div class="hand op" style="margin-right: 10px" @click="showTemplateInfo(item)">
                                    <img src="./imgs/look.png">
                                    <div style="text-align: center">预览</div>
                                </div>
                                <div class="hand op" @click="$router.push({path: '/form/diy', query: {id: item.id, name: item.title, roomId: item.roomId}})">
                                    <img src="./imgs/write.png">
                                    <div style="text-align: center">编辑</div>
                                </div>
                                <div class="hand op" @click="toMenu(item.id)">
                                    <img src="./imgs/return.png" style="margin-left: 10px">
                                    <div style="text-align: center">分享</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="padding: 10px 32px;text-align: center">
                        <div>{{item.title}}</div>
                        <hr style="margin: 6px 0">
                        <div style="color: #999999; font-size: 14px">{{item.createTime}}</div>
                    </div>
                </div>
            </div>
        </el-dialog>

        <!--查看模板弹窗-->
        <el-dialog class="show-message" :visible.sync="showTemplate" width="60%" @close="closeTemplate">
            <div style="display: flex;">
                <div class="dialog-title">查看模板</div>
            </div>
            <div class="message-divider">
                <el-divider></el-divider>
            </div>
            <div class="message-body">
                <div style="margin-bottom: 40px">
                    <form-builder v-if="showTemplate === true" ref="formBuilder" v-model="formVal" :buildData="formCode"></form-builder>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import Head from "../../components/common/Head";
    import FormBuilder from "../../components/common/formDesigner/formBuilder";
    export default {
        name: "menus",
        components: {FormBuilder, Head},
        data() {
            return {
                normal: true,
                edit: false,
                selfMenuName: '',
                showMenuAdd: false,
                defaultMenu: [],
                deleteId: '',
                showDeleteMenu: false,
                menuId: '',
                menuClass: '',
                roomFrom: {
                    name: '',
                    menuType: '',
                    readPermission: [],
                    writePermission: [],
                    sort: '',
                    isFidShow: 0,
                },
                page: 1,
                pageSize: 20,
                hotDiyTemplates: [],

                selfMenuList: [],
                optionMenu: [],
                optionTemplate: [],

                hotList: [],

                showRoom: false,
                myShowRoomList: [],
                roomId: '',
                templateId: '',

                // 管理Room菜单
                showManage: false,
                showTemplate: false,
                formVal: '',
                formCode: '',

                toId: 0,
                roomInfo: {},

                subMenuList: []  // 子room菜单列表
            }
        },

        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            },
        },

        mounted() {
            this.getMenuList();
            this.getRoom();
            this.getTheRoomInfo()
        },

        methods: {
            // 设置子Room展示
            selectSubMenu(status, id) {
                this.newApi.setSubRoomShareMenuShow({menuId: id, status: status}).then(res => {
                    if (res.isSuccess === 1) {
                        this.getSubMenuList()
                    }
                })
            },

            // 获取子room菜单列表
            getSubMenuList() {
                this.newApi.getSubRoomShareMenuList({roomId: this.$route.params.id}).then(res => {
                    if (res.isSuccess === 1) {
                        this.subMenuList = res.data;
                    }
                })
            },

            // 获取Room详情
            getTheRoomInfo() {
                this.newApi.getRoomInfo({roomId: this.$route.params.id}).then(res => {
                    if (res.isSuccess === 1) {
                        if (res.data.roomType === 3) {
                            this.getSubMenuList()
                        }
                        this.roomInfo = res.data;
                    }
                })
            },

            // 关闭模板弹窗
            closeTemplate() {
                this.formVal = '';
                this.formCode = ''
            },

            // 管理Room模板
            manageRoomTel() {
                this.showManage = true;
                this.getHotDiy('3')
            },


            // 查看模板信息
            showTemplateInfo(item) {
                this.showTemplate = true;
                this.formVal = JSON.stringify(item.json.list);
                this.formCode = JSON.stringify(item.json)
            },

            // 删除Room模板
            deleteRoomTemplate(id) {
                var that = this;
                this.utils.confirm('确定删除此自定义模板？', function () {
                    that.newApi.deleteRoomDiyTemplate({id: id}).then(res =>{
                        if (res.isSuccess === 1) {
                            that.utils.sus(res.data);
                            that.getHotDiy('3');
                        }
                    })
                })
            },

            // 展示room弹窗
            toMenu(id) {
                this.toId = id;
                this.showRoom = true;
            },

            // 获取Room
            getRoom() {
                this.newApi.getMyDutyRoomList({type: 0, roomType: 0}).then(res =>{
                    this.myShowRoomList = res.data;
                })
            },

            // 分享模板到room
            joinRoom() {
                this.newApi.shareRoomDiyTemplate({id: this.toId, roomId: this.roomId}).then(res => {
                    if (res.isSuccess === 1) {
                        this.showRoom = false;
                        this.roomId = 0;
                        this.toId = 0;
                        this.utils.sus(res.data)
                    }
                })
            },

            // 切换写权限
            selectWritePermission(e, item) {
                this.newApi.editRoomMenuAuth({menuId: item.id, readPermission: JSON.stringify(item.readPermission), writePermission: JSON.stringify(item.writePermission)}).then(res => {
                    if (res.isSuccess === 1) {
                        this.getMenuList()
                    }
                })
            },

            // // 通用模板选中
            // selectPush(e, item) {
            //     this.hotDiyTemplates.push(item);
            //     for (let i = 0; i < this.hotList.length; i++) {
            //         if (this.hotList[i].normal) {
            //             this.hotList.splice(i,1)
            //         }
            //     }
            //     var param = {};
            //     param.roomId = this.$route.params.id;
            //     param.name = item.name;
            //     param.menuType = item.menuType;
            //     param.sort = item.sort;
            //     param.type = item.type;
            //     param.kwType = 0;
            //     param.isFidShow = item.isFidShow;
            //     param.readPermission = JSON.stringify(item.readPermission);
            //     param.writePermission = JSON.stringify(item.writePermission);
            //     this.newApi.addRoomMenu(param).then(res => {
            //         if (res.isSuccess === 1) {
            //             this.getMenuList()
            //         }
            //     })
            // },

            // 通用模板取消选中
            deletePush(e, item) {
                if (item) {
                    this.deleteId = item.id;
                    this.newApi.delRoomMenu({id: this.deleteId}).then(res => {
                        if (res.isSuccess === 1) {
                            this.deleteId = '';
                            this.showDeleteMenu = false;
                            this.getMenuList()
                        } else {
                            item.normal = true
                        }
                    })
                }
            },

            // 删除菜单
            deleteMenu(id) {
                this.showDeleteMenu = true;
                this.deleteId = id
            },

            deleteMenuClick() {
                this.newApi.delRoomMenu({id: this.deleteId}).then(res => {
                    if (res.isSuccess === 1) {
                        this.deleteId = '';
                        this.showDeleteMenu = false;
                        this.getMenuList()
                    }
                })
            },


            // 添加
            addMenu(e) {
                if (e === '3') {
                    this.optionMenu = this.selfMenuList
                }
                if (e === '1') {
                    this.optionMenu = this.defaultMenu
                }
                if (e === '2') {
                  this.optionMenu = this.hotDiyTemplates
                }
                this.getHotDiy(e);
                this.menuId = '';
                this.menuClass = e;
                this.roomFrom = {
                    name: '',
                    menuType: '',
                    readPermission: [],
                    writePermission: [],
                    sort: '',
                    isFidShow: 0,
                };
                this.showMenuAdd = true;
            },

            // 取消操作
            cancelMenu() {
                this.showMenuAdd = false;
                this.menuClass = '';
                this.getMenuList()
            },

            // 操作菜单

            // 添加
            changeMenu() {
                var param = {};
                param.roomId = this.$route.params.id;
                param.name = this.roomFrom.name;
                param.menuType = this.roomFrom.menuType;
                param.sort = this.roomFrom.sort;
                param.isFidShow = this.roomFrom.isFidShow;
                param.kwType = 0;
                if(param.menuType == 68){
                    param.kwType = 1;
                }
                param.readPermission = JSON.stringify(this.roomFrom.readPermission);
                param.writePermission = JSON.stringify(this.roomFrom.writePermission);
                if (this.menuClass == '2') {
                  param.name = this.optionTemplate.find(object => object.id === this.roomFrom.menuType).title
                }
                if (param.menuType === '') {
                    this.utils.err('请选择菜单内容格式！');
                    return
                }

                if (param.name.length < 2) {
                    this.utils.err('菜单名称过短！');
                    return
                }

                if (this.menuId === '') {
                    param.type = this.menuClass;
                    this.newApi.addRoomMenu(param).then(res => {
                        if (res.isSuccess === 1) {
                            this.showMenuAdd = false;
                            this.getMenuList()
                        }
                    })
                } else {
                    param.id = this.menuId;
                    this.newApi.editRoomMenu(param).then(res => {
                        if (res.isSuccess === 1) {
                            this.showMenuAdd = false;
                            this.getMenuList()
                        }
                    })
                }
            },

            // 编辑
            editMenu(item) {
                this.menuId = item.id;
                this.showMenuAdd = true;
                this.roomFrom = item;
                this.roomFrom.menuType = String(item.menuType);
            },

            // 获取room菜单
            getMenuList() {
                var that = this;
                that.newApi.getRoomMenuList({roomId: this.$route.params.id, type: 1}).then(res => {
                    that.defaultMenu = res.data.default;
                    that.selfMenuList = res.data.diy;
                    for (const item of res.data.template) {
                        item['normal'] = true
                    }
                    that.hotDiyTemplates = res.data.template;
                    if (that.hotDiyTemplates.length !== 0) {
                        for (let i = 0; i < that.hotList.length; i++) {
                            for (let j = 0; j < that.hotDiyTemplates.length; j++) {
                                if (that.hotList[i].name === that.hotDiyTemplates[j].name) {
                                    that.hotList.splice(i,1)
                                }
                            }
                        }
                    }
                })
            },

            // 获取通用模板文档
            getHotDiy(e) {
                if (e === '3') {
                    this.newApi.getRoomTemplateList({roomId: this.$route.params.id, page: this.page, pageSize: this.pageSize, type: JSON.stringify([e])}).then(res => {
                        for (const item of res.data.diy) {
                            item.show = false
                        }
                        this.optionTemplate = res.data.diy
                    })
                }
                if (e === '1') {
                    this.newApi.getRoomTemplateList({roomId: this.$route.params.id, page: this.page, pageSize: this.pageSize, type: JSON.stringify([e])}).then(res => {
                        this.optionTemplate = res.data.default
                    })
                }
                if (e === '2') {
                  this.newApi.getRoomTemplateList({roomId: this.$route.params.id, page: this.page, pageSize: this.pageSize, type: JSON.stringify([e])}).then(res => {
                    this.optionTemplate = res.data.template
                  })
                }
            },

            // 返回上一页
            goBack() {
                this.$router.go(-1)
            },
        }
    }
</script>

<style scoped>
    .resume-title {
        font-size: 20px;
        font-weight: 600;
    }

    .resume-res {
        font-size: 15px;
        color: #999999;

    }

    .card-box {
        display: flex;
        flex-wrap: wrap;
    }

    .card {
        width: 322px;
        margin-right: 20px;
        margin-bottom: 20px;
        border: 1px #CED0D4 solid;
    }

    .card-title {
        height: 50px;
        padding: 0 20px;
        line-height: 50px;
        display: flex;
        justify-content: space-between;
        background-color: #F8F8F8;
        border-bottom: 1px #CED0D4 solid;
    }

    .card-li {
        height: 50px;
        line-height: 50px;
        padding: 0 20px;
        border-bottom: 1px #CED0D4 solid;
    }

    .show-message .head-ico {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin-right: 20px;
    }

    .show-message .dialog-title {
        height: 30px;
        padding: 10px 0;
        font-weight: 600;
        margin: 0 auto;
        font-size: 20px;
    }

    .show-message .message-body {
        min-height: 200px;
        padding: 20px;
    }

    .el-checkbox {
        margin-right: 10px;
    }

    /deep/ .card-title .el-checkbox__input.is-disabled+span.el-checkbox__label {
        color: #000000;
        font-size: 16px;
    }

    /deep/ .card-title .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
        background-color: #409EFF;
        border-color: #409EFF;
    }

    .show-message .list-content {
        width: 308px;
        height: 64px;
        border: 1px #CED0D4 solid;
        margin: 0 20px 10px 0;
    }

    .show-message .title-form {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 12px;
    }

    .show-message .el-divider--horizontal {
        margin: 0;
    }

    /deep/ .show-message .el-radio__label {
        display: none;
    }

    /deep/ .show-message .el-dialog__header{
        padding: 0;
    }

    /deep/ .show-message .el-dialog__body{
        padding: 0;
    }
</style>
